import Script from "next/script";

export const GTM_TRACKING_ID = "GTM-5RDDTFX";

export const pageview = (url) => {
    const pageEvent = {
        event: "pageview",
        page: url,
    }

    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
}

export const render = () => {
    return (
        <>
            {/* Google Tag Manager */}
            <Script
                strategy="afterInteractive"
                id="GoogleTagManager"
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`,
                }}
            />
            {/* End Google Tag Manager (noscript) */}

            {/* Google Tag Manager */}
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            />
            {/* End Google Tag Manager (noscript) */}
        </>
    );
}
